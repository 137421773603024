

.stat-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  margin: 1em;
}

stat-column {
  /* display: flex; */
  width: 50%;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  margin: 1em;
}