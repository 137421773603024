.button-toggle {
  display: flex;
  gap: 10px;
}

.toggle-button {
  padding: 10px 20px;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.active-true,
.active-true:hover {
  background-color: #28a745;
  color: white;
}

.active-false,
.active-false:hover {
  background-color: #dc3545;
  color: white;
}

.toggle-button:not(.active-true):not(.active-false) {
  background-color: #6c757d;
}
