.stat-box {
  display: flex;
  width: 25em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: #1affa7;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  padding: 1em;
  margin-left: 10em;
  margin-right: 10em;
  margin-top: 2em;
  margin-bottom: 2em;
}

.stat-title {
  font-size: 1.25em;
  color: #fff;
}

.stat-value {
  font-size: 2em;
  color: #1affa7;
}