.detailHeader {
  text-align: left;
  width: 90%;
  padding: 1em;
}

.teesContainer {
  width: 90%;
  padding: 1em;
  margin-bottom: 1em;
  overflow-x: auto;
}

.scoreCardContainer {
  width: 100%;
  margin-bottom: 5em;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  /* padding: 8px 16px; */
  padding: .2em .4em;
  text-align: left;
  border: 1px solid #1affa7;
  white-space: nowrap;
}

th {
  background-color: #020202;
}

.tableInput {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: 1em;
  border: none;
  color: #fff;
  background-color: #020202;
  font-weight: lighter;
}

.tableInput:focus {
  outline: none;
}

h1 {
  color: #1affa7;
  font-size: 1.5em;
  text-align: left;
}

h2 {
  color: #fff;
  font-size: 1.25em;
  text-align: left;
}

.scorecardSubtext {
  text-align: left;
}

p {
  color: #fff;
  line-height: 25%;
}

label {
  color: #1affa7;
  font-size: .65em;
  font-weight: bold;
}

.formRow {
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
}

.mainFormColumn {
  display: flex; 
  flex-direction: column;
  flex: 4;
}

.secondaryFormColumn {
  display: flex; 
  flex-direction: column;
  flex: 1;
}

.clubName {
  font-size: 1em;
  font-weight: bold;
  line-height: 0%;
  text-align: left;
}

.address {
  font-size: .85em;
  line-height: 0%;
  text-align: left;
}

.courseNameInput {
  font-size: xx-large;
  width: 100%;
  color: #fff;
  background-color: #020202;
  border: 1px solid #020202;
  padding: 0em;
  padding-bottom: .5em;
}

.descriptionInput {
  font-size: regular;
  width: 100%;
  resize: none;
  color: #fff;
  background-color: #020202;
  border: 1px solid #020202;
  border-radius: 0.5em;
  outline: none;
  padding: .25em;
  padding-bottom: .5em;
}

.normalInput {
  font-size: regular;
  width: 100%;
  color: #fff;
  background-color: #020202;
  border: 1px solid #020202;
  padding: .25em;
  font-weight: lighter;
  padding-bottom: .5em;
}

.clubNameInput {
  font-size: large;
  width: 100%;
  color: #fff;
  background-color: #020202;
  border: 1px solid #020202;
  padding: .1em;
  padding-bottom: .5em;
}

.addressInput {
  font-size: regular;
  width: 100%;
  color: #fff;
  background-color: #020202;
  border: 1px solid #020202;
  padding: .1em;
  font-weight: lighter;
  padding-bottom: 1em;
}

.banner {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1affa7;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  z-index: 1000;
}

.addTeeButton {
  padding: .1em;  
  border: none;
  font-weight: bold;
  /* width: 90px; */
  border-radius: 10px;
  background-color: #1affa7;
  color: #020202;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  th, td {
    font-size: 0.9em;
  }

  .tableInput {
    font-size: 0.9em;
  }
}
