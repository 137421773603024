.App {
  background-color: #020202;
  text-align: center;
  height: calc(100vh);
}

.page {
  background-color: #020202;
  overflow-y: auto;
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.page-mobile {
  background-color: #020202;
  overflow-y: auto;
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.formInput {
  padding: 12px;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 10px;
}

.formInput:focus {
  outline: none;
}

button {
  padding: 12px;  
  border: none;
  font-weight: bold;
  width: 90px;
  border-radius: 10px;
  background-color: #1affa7;
  color: #020202;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

button:hover { 
  background-color: #17a46e;
  color: #020202;
}

button:active {
  /* Styles when the button is clicked */
  background-color: #17a46e; /* Darker shade for effect */
  color: #020202;
  transform: translateY(2px); /* Slightly move down */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for depth */
}

.text {
  color: #ffffff;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}