.header {
  background-color: #020202;
  width: 100%; 
  justify-content: space-between;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 1em;
}

.title, .terms, .contact, .manage {
  color: #ffffff;
  padding: 1em; 
  font-size: 1.25em;
  cursor: pointer;
}

.left, .right {
  display: flex;
}

.logo {
  width: 100px;
  height: 100px;
  /* object-fit: cover; */
}

.logo-mobile {
  width: 50px;
  height: 50px;
  /* object-fit: cover; */
}