

.page {
  display: flex;
  width: 100%;
}

.page-mobile {
  display: flex;
  width: 100%;
}