.search-page {
  background-color: #020202;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.search-page-mobile {
  background-color: #020202;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.website {
  color: #1affa7;
}

.website:visited {
  color: #ffffff;
}

.table-container { 
  width: 90%;
  margin: 0 auto;  
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse; /* Makes borders collapse into a single border */
}

table, th, td {
  border: 1px solid #1affa7; /* Sets the color of the table borders */
}

th, td {
  padding: .1em; /* Adds space around the table content */
  text-align: center; /* Aligns text to the left */  
  max-width: 25ch; /* Limit the maximum width to 20 characters */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
  background-color: #020202; /* Sets the background color of the table headers */
}

tr:nth-child(even) {
  background-color: #020202; /* Sets the background color of even rows */
}

tr:hover {
  cursor: pointer;
  background-color: #222222; /* Sets the background color when hovering over a row */
}

.createCourseButton {
  padding: 12px;  
  /* border: none; */
  font-weight: bold;
  width: 180px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #1affa7;
  border-style: solid;
  /* background-color: #1affa7;
  color: #020202; */
  background-color: #020202;
  color: #1affa7;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  cursor: pointer;
  /* outline: none; */
  transition: all 0.3s ease;
}